import axios from 'axios';
import FormData from 'form-data';
import getCookie from './utils/getCookie';

const uploadStudentDetailsService = (
  studentData,
  stepNo = '',
  isSubmit = false,
  paymentData= null
) => {
  console.log('studentData', studentData);

  try{
    let tokenData = getCookie('tokenData');
    tokenData = JSON.parse(tokenData);
    const access_token = tokenData && tokenData.access_token;

  if(!access_token) {
    alert('your are not authorized and redirecting to Home page.')
    window.location.pathname = '/';
  }

  // var FormData = require('form-data');
  var data = new FormData();
  // studentData.phone
  data.append('fullName', studentData.fullName);
  data.append('aadhar', studentData.aadhar);
  data.append('date1', studentData.aadhar);
  data.append('birth', studentData.birthPlace);
  data.append('photo', studentData.photo ? true : false);
  data.append('siblings', studentData.siblings ? '1' : '0');
  data.append('dateOfBirth', studentData.dateOfBirth);
  data.append('class1', studentData.class);
  data.append('birthPlace', studentData.birthPlace);
  data.append('nationality', studentData.nationality);
  data.append('religion', studentData.religion);
  data.append('motherTongue', studentData.motherTongue);
  data.append('gender', studentData.gender);
  if(studentData.application_id !== "")
  data.append('application_id', studentData.application_id);
  if(studentData.application_id === "")
  data.append('application_id', 7);
  if (stepNo !== '1') {
    data.append('fatherName', studentData.fatherName);
    data.append('motherName', studentData.motherName);
    data.append('phone', studentData.phone);
    data.append('phone1', studentData.phone1);
    data.append('email', studentData.email);
    data.append('email1', studentData.email1);
    data.append('fatherLang', studentData.fatherLang);
    data.append('motherLang', studentData.motherLang);
    data.append('resadd', studentData.resadd);
    data.append('resadd1', studentData.resadd1);
    data.append('area', studentData.area);
    data.append('area1', studentData.area1);
    data.append('dist', studentData.dist);
    data.append('dist1', studentData.dist1);
    data.append('state', studentData.state);
    data.append('state1', studentData.state1);
    data.append('country', studentData.country);
    data.append('country1', studentData.country1);
    data.append('pin', studentData.pin);
    data.append('pin1', studentData.pin1);
    data.append('offnum', studentData.offnum);
    data.append('offnum1', studentData.offnum1);
    data.append('resnum', studentData.resnum);
    data.append('resnum1', studentData.resnum1);
    data.append('gross', studentData.gross);
    data.append('gross1', studentData.gross1);
    data.append('working', studentData.working ? '1' : '0');
    data.append('working1', studentData.working1 ? '1' : '0');
  }
  data.append('transactionId', paymentData ? paymentData.razorpay_payment_id : '');
  data.append('orderID', paymentData ? paymentData.razorpay_order_id : '');
  data.append('transactionSignature', paymentData ? paymentData.razorpay_signature : '');
  if (isSubmit) {
    data.append('status', 'SUBMITTED');
  } else {
    data.append('status', 'DRAFT');
  }


  var config = {
    method: 'post',
    url: 'http://204.236.192.144:49159/api/account/update-application',
    headers: {
      Authorization:
        `Bearer ${access_token}` ,
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  catch{
    alert('Bad URL');
    window.location.pathname = '/error';
  }
};

export default uploadStudentDetailsService;
