import axios from 'axios';
import FormData from 'form-data';
import getCookie from './utils/getCookie';
//import fs from 'fs';

const uploadFileService = (documentType, fileStream) => {
  var data = new FormData();

try{
  let tokenData = getCookie('tokenData');
  tokenData = JSON.parse(tokenData);
  const access_token = tokenData && tokenData.access_token;
  const user = tokenData && tokenData.user;

  const email_id = user.email_id;
  const id = user.id;

  if(!access_token) {
    alert('your are not authorized and redirecting to Home page.')
    window.location.pathname = '/';
  }


  data.append('email_id', email_id);
  data.append('document_type', documentType);
  data.append('id', id);
  data.append(
    'file',
    fileStream,
  );

  var config = {
    method: 'post',
    url: 'http://204.236.192.144:49159/api/account/upload-file',
    headers: {
      Authorization:
        `Bearer ${access_token}`,
    },
    data: data
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  catch{
        window.location.pathname = '/error';
  }

};


export default uploadFileService;

