import React from 'react';
import retrieveImageData from './retrieveImageData';

class FileUploadViewer extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/prop-types
    this.state = {
      file: props.file,
      imagePreviewUrl: props.imagePreviewUrl,
      fileName: props.fileName,
      imageData: null,
    };
  }

  componentDidMount() {
    if (this.props.applicationNo && this.props.imageType) {
     
      retrieveImageData(this.props.applicationNo, this.props.imageType ).then(
        (res) => {
          const uri = window.URL.createObjectURL(res);
          this.setState({ imageData: uri });
        },
      );
    }
  }

  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      if (file.size > 1000 * 1000) {
        alert('Please select the file less than 200 KB.');
      } else {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
          fileName: file.name,
        });
        // eslint-disable-next-line react/prop-types
        this.props.handleUpdateFileInfo(file, file.name, reader.result);
      }
    };

    reader.readAsDataURL(file);
  }

  render() {
    let { imagePreviewUrl } = this.state;
    // let $imagePreview = null;
    // if (imagePreviewUrl) {
    //   $imagePreview = (
    //     <img key={`fileName-${this.state.fileName}`} src={imagePreviewUrl} />
    //   );
    // } else {
    //   $imagePreview = (
    //     <div className="previewText">Please select an Image for Preview</div>
    //   );
    // }

    return (
      <div
        className="previewComponent"
        key={`image-${this.props.info || 'test'}`}
      >
        <div>
          {!this.props.uploadNotRequired && (
            <>
              <input
                className="fileInput"
                type="file"
                id={`selectFile-${this.props.info}`}
                style={{ display: 'none' }}
                onChange={(e) => this.handleImageChange(e)}
              />
              <label
                htmlFor="img"
                color="blue"
                onClick={() =>
                  document
                    .getElementById(`selectFile-${this.props.info}`)
                    .click()
                }
              >
                <img
                  height={50}
                  width={50}
                  src="https://png.pngtree.com/element_our/20190601/ourmid/pngtree-upload-icon-image_1347930.jpg"
                />
              </label>
            </>
          )}
          {/* {this.state.fileName && ( */}
          <div id={`${this.props.info} - ${this.state.fileName}`}>
            <span style={{ marginTop: 20 }}>
              Selected File Name : {this.state.fileName || (
                this.state.imageData ? 'Student Aadhar' : 'NO File Selected')}
            </span>
          </div>
          {/* )} */}
          <div>
            {this.state.fileName && (
              <img
                height={150}
                width={150}
                key={`fileName-${this.state.fileName}`}
                src={imagePreviewUrl}
              />
            )}
            {this.state.imageData && (
              <img
                height={150}
                width={150}
                key={`fileName-${this.state.fileName}`}
                src={this.state.imageData}
              />
            )}

        
          </div>
        </div>
      </div>
    );
  }
}

export default FileUploadViewer;
